/**
 *
 * useSearch Hook
 * @author Chad Watson
 *
 *
 */
import { FUSEJS_SCORE_DISTANCE, FUSEJS_SCORE_THRESHOLD } from "config/app";
import Fuse from "fuse-immutable";
import { useCallback, useMemo, useState } from "react";
const defaults = {
  keys: [""],
  tokenize: false,
  matchAllTokens: false,
  sortByScore: true,
};

type FuseOptions = {
  keys?: string[];
  tokenize?: boolean;
  matchAllTokens?: boolean;
  sortByScore?: boolean;
};

export function useFuse(collection, options?: FuseOptions) {
  const { keys, tokenize, matchAllTokens, sortByScore } = options
    ? options
    : defaults;

  const fuse = useMemo(
    () =>
      new Fuse(collection, {
        ...defaults,
        threshold: FUSEJS_SCORE_THRESHOLD,
        distance: FUSEJS_SCORE_DISTANCE,
        keys,
        tokenize,
        matchAllTokens,
        includeScore: sortByScore,
      }),
    [keys, tokenize, matchAllTokens, sortByScore, collection]
  );

  return useCallback((query) => (query ? fuse.search(query) : collection), [
    collection,
    fuse,
  ]);
}
export default function useSearch(collection, options) {
  const search = useFuse(collection, options);
  const [results, setResults] = useState(collection);
  return [
    results,
    useCallback(
      (query) => {
        setResults(search(query));
      },
      [search]
    ),
  ];
}
