/**
 *
 * PendingConfirmModal
 * @author Tyler Torres, Chad Watson
 *
 *
 */
import ConfirmModal from "components/LegacyConfirmModal";
import { useDispatch, useStoreSelector } from "hooks/useRedux";
import { useDaemons } from "hooks/useSaga";
import React from "react";
import { cancel, confirm } from "store/pendingConfirm/actions";
import { allPendingConfirmDaemons } from "store/pendingConfirm/sagas";
import { selectCurrentPendingConfirm } from "store/pendingConfirm/selectors";
export default function PendingConfirmModal() {
  const pendingConfirm = useStoreSelector(selectCurrentPendingConfirm);
  const dispatch = useDispatch();
  useDaemons(...allPendingConfirmDaemons);
  return (
    <ConfirmModal
      {...pendingConfirm.map((record) => record.toJS()).getOrElse({})}
      onConfirm={() => {
        dispatch(confirm());
      }}
      onCancel={() => {
        dispatch(cancel());
      }}
    />
  );
}
