/**
 *
 * NotificationsContainer
 * @author Chad Watson
 *
 */

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectNotificationsList } from "store/notifications/selectors";
import { clearNotification } from "store/notifications/actions";
import Notifications from "components/Notifications";

const mapStateToProps = createStructuredSelector({
  notifications: selectNotificationsList
});

const mapDispatchToProps = {
  onClearNotification: clearNotification
};

const enhance = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default enhance(Notifications);
