/**
 *
 * Api URLS
 * @author Matt Shaffer, Chad Watson
 *
 *
 */
import environment from "./environment";
export const vk = environment.vkUrl;
export const odata = environment.odataUrl;
export const reports = environment.reportsUrl;
export const jobs = environment.jobsUrl;
export const jobStat = environment.jobStatUrl;
export const payServer = environment.payServerUrl;
export const streamer = environment.streamerUrl;
export const dealerApi = environment.dealerApiUrl;
export const thirdPartyVideo = environment.thirdPartyVideoUrl;
export const camect = environment.camectUrl;
export const pulse = environment.pulseUrl;
export const logos = environment.logosUrl;

export const odataEventReports = environment.odataEventReportsUrl;
export const odataEventHistories = environment.odataEventHistoriesUrl;
