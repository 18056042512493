import Maybe from "data.maybe";
import { Map } from "immutable";
import { compose, prop } from "ramda";
import { parseLocationSearch } from "utils";

const panelBorderColor = "#E0E0E0";
const fallbackFontFamily = '"Segoe UI", Roboto, Helvetica, Arial, sans-serif';

export const defaultPrimaryTheme = {
  textColor: "#7F8386",
  primaryBackgroundColor: "#EDF0F5",
  secondaryBackgroundColor: "#FFFFFF",
  tertiaryBackgroundColor: "#F9FAFF",
  borderColor: "#F0F0F0",
  panelBorderColor,
  trueWhite: "#FFFFFF",
  trueBlack: "#000000",
  primary: "#8F95F2",
  primary100: "#f5f5ff",
  primary200: "#ccceff",
  primary300: "#a1a4fc",
  primary400: "#8888fc",
  primary500: "#726bfa",
  primary600: "#5f57fa",
  primary700: "#4b3bf7",
  primary800: "#3424e5",
  primary900: "#1d0ebe",
  primary1000: "#0b008a",
  secondary: "#22355A",
  secondaryDark: "#0A1C3B",
  secondaryLight: "#495C82",
  success: "#5CB85C",
  save: "#5CB85C",
  caution: "#FFDA3E",
  warning: "#FF7833",
  failure: "#DD1C24",
  danger: "#ED1C24",
  grayXlight: "#F9FAFF",
  grayLight: "#EDF0F5",
  grayMediumLight: "#d0d3d8",
  grayMedium: "#6b7280",
  gray: "#383F45",
  grayDark: "#161e2e",
  grayAccent: "#CCCCCC",
  grayAccentLight: "#B3B3B3",
  red: "#ED1C24",
  red100: "#FFE3E3",
  red200: "#FFBDBD",
  red300: "#FF9B9B",
  red400: "#F86A6A",
  red500: "#EF4E4E",
  red600: "#E12D39",
  red700: "#CF1124",
  red800: "#AB091E",
  red900: "#8A041A",
  red1000: "#610316",
  orange: "#FF7833",
  yellow: "#FFDA3E",
  yellowLight: "#FFE471",
  yellow100: "#FFFBEA",
  yellow200: "#FFF3CA",
  yellow300: "#FCE588",
  yellow400: "#FADB5F",
  yellow500: "#F7C948",
  yellow600: "#F0B429",
  yellow700: "#DE911D",
  yellow800: "#CB6E17",
  yellow900: "#B44D12",
  yellow1000: "#8D2B0B",
  green: "#5CB85C",
  green100: "#E3F9E5",
  green200: "#C1EAC5",
  green300: "#A3D9A5",
  green400: "#7BC47F",
  green500: "#57AE5B",
  green600: "#3F9142",
  green700: "#2F8132",
  green800: "#207227",
  green900: "#0E5814",
  green1000: "#05400A",
  blueDark: "#2F3640",
  coolBlue: "#65A4EF",
  purple: "#8F95F2",
  boxShadow: (color = "rgba(0,0,0,0.25)") => `0 1px 8px ${color}`,
  panelBorder: `1px solid ${panelBorderColor}`,
  highlightColor: "#FFFBCC",
  fallbackFontFamily,
  primaryFontFamily:
    "Inter var,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji",
  elevation0: "0 0 0 rgba(0,0,0,0)",
  elevation100: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
  elevation200: "0 3px 6px rgba(0,0,0,0.15), 0 2px 4px rgba(0,0,0,0.12)",
  elevation300: "0 10px 20px rgba(0,0,0,0.15), 0 3px 6px rgba(0,0,0,0.10)",
  elevation400: "0 15px 25px rgba(0,0,0,0.15), 0 5px 10px rgba(0,0,0,0.05)",
  elevation500: "0 20px 40px rgba(0,0,0,0.2)",
  statusBlue: "#38b6e0",
  statusGreen: "#7cc75a",
  statusOrange: "#ffb224",
  statusRed: "#ff3324",
  badgeGreen: "#5CB85C",
  badgeYellow: "#dec316",
  detectionRegionBlue: "#285FF6",
  detectionRegionRed: "#D13A20",
  detectionRegionGreen: "#5CB85C",
  detectionRegionYellow: "#FFDA3E",
};
const defaultThemes = Map({
  primary: defaultPrimaryTheme,
});
const bayThemes = Map({
  primary: {
    ...defaultPrimaryTheme,
    primary: "#DA262E",
  },
});
export const createThemes = (location) =>
  Maybe.fromNullable(location)
    //@ts-ignore
    .chain(compose(Maybe.fromNullable, prop("theme"), parseLocationSearch))
    .map((theme) => {
      switch (theme) {
        case "bay":
          return bayThemes;

        default:
          return defaultThemes;
      }
    })
    .getOrElse(defaultThemes);
