export const vkUrl = "#{OCT_V4_VK_URL}";
export const legacyVkUrl = "#{OCT_VK_URL}";
export const odataUrl = "#{OCT_ODATA_URL}";
export const jobsUrl = "#{OCT_JOBS_URL}";
export const jobStatUrl = "#{OCT_JOB_STATS_URL}";
export const payServerUrl = "#{OCT_PAY_SERVER_URL}";
export const reportsUrl = "#{OCT_REPORTS_URL}";
export const dealerApiUrl = "#{OCT_DEALER_API_URL}";
export const streamerUrl = "#{OCT_STREAMER_URL}";
export const thirdPartyVideoUrl = "#{OCT_THIRD_PARTY_VIDEO_URL}";
export const camectUrl = "#{OCT_CAMECT_URL}";
export const pulseUrl = "#{OCT_PULSE_URL}";
export const logosUrl = "#{OCT_LOGOS_URL}";

export const odataEventReportsUrl = "#{OCT_ODATA_EVENT_REPORTS_URL}";
export const odataEventHistoriesUrl = "#{OCT_ODATA_EVENT_HISTORIES_URL}";

export const HTTP_ACCESSIBLE_TYPE = "#{OCT_HTTP_ACCESSIBLE_TYPE}";
