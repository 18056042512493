/**
 *
 * useBreakpoints
 * @author Chad Watson
 *
 *
 */
import { useContext } from "react";
import { Context } from "components/Breakpoints";

const useBreakpoints = () => useContext(Context);

export const useMinBreakpoint = getBreakpoint => {
  const { breakpointNames, minBreakpoint } = useBreakpoints();
  return minBreakpoint(getBreakpoint(breakpointNames));
};
export const useMaxBreakpoint = getBreakpoint => {
  const { breakpointNames, maxBreakpoint } = useBreakpoints();
  return maxBreakpoint(getBreakpoint(breakpointNames));
};
export default useBreakpoints;
