import { useRef, useLayoutEffect } from "react";
import ReactDOM from "react-dom";
/**
 * @deprecated Prefer the Portal component instead -> "common/components/web/portal.ts"
 */
export const usePortal = (content: React.ReactNode) => {
  const ref = useRef(document.createElement("div"));
  useLayoutEffect(() => {
    const node = ref.current;

    if (node) {
      document.body.appendChild(node);
      return () => {
        document.body.removeChild(node);
      };
    }
  }, []);
  return ReactDOM.createPortal(content, ref.current);
};
