/**
 *
 * useSaga Custom Hook
 * @author Chad Watson
 *
 *
 */
import { useEffect } from "react";
import { useStore } from "./useRedux";
import { DAEMON } from "utils/constants";

export function useSaga(saga) {
  const store = useStore();
  return store.runSaga(saga);
}
export function useDaemon(daemon) {
  const store = useStore();

  if (!store.injectedSagas[daemon.name]) {
    store.injectedSagas[daemon.name] = {
      key: daemon.name,
      saga: daemon.saga,
      mode: DAEMON,
      task: store.runSaga(daemon.saga),
    };
  }
}
export function useDaemons(...args) {
  args.forEach(useDaemon);
}
export function useCancelOnUnmount(task) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => task.cancel(), []);
}
