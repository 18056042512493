/**
 *
 * App constants
 * @author Chad Watson
 *
 *
 */
export const ENTER = 13;
export const SPACE_BAR = 32;
export const LEFT_ARROW = 37;
export const UP_ARROW = 38;
export const RIGHT_ARROW = 39;
export const DOWN_ARROW = 40;
export const BACKSPACE = 8;
export const ESCAPE = 27;
