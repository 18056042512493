/**
 *
 * Use International (react-intl)
 * @author Seth MacPherson
 *
 *
 */
import { map } from "ramda";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
export { useIntl } from "react-intl";
export const messagePropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string.isRequired,
  message: PropTypes.string
});
export const useFormattedMessage = (messages, values) =>
  useIntl().formatMessage(messages, values);
export const useFormattedMessages = messages => {
  const { formatMessage } = useIntl();
  return map(formatMessage, messages);
};
