import { useState } from "react";
import useEffectOnce from "hooks/useEffectOnce";

const useWindowDimensions = () => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffectOnce(() => {
    let timeout = null;
    const handleResize = () => {
      clearTimeout(timeout);
      setTimeout(() => {
        setDimensions({
          width: window.innerWidth,
          height: window.innerHeight
        });
      }, 50);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener("resize", handleResize);
    };
  });

  return dimensions;
};

export default useWindowDimensions;
