/**
 *
 * ConfirmModal
 * @author Chad Watson
 *
 */

import ConfirmModal from "components/LegacyConfirmModal";
import { useDispatch, useStoreSelector } from "hooks/useRedux";
import { useDaemon } from "hooks/useSaga";
import React from "react";
import { cancel, confirm } from "store/confirm/actions";
import { presentConfirmDaemon } from "store/confirm/sagas";
import { selectConfirmMessage } from "store/confirm/selectors";

/**
 * @deprecated Prefer using `<ConfirmModal />` from projects/virtual-keypad/src/components/ConfirmModal instead.
 */
export default function ConfirmModalContainer() {
  const message = useStoreSelector(selectConfirmMessage);
  const dispatch = useDispatch();

  useDaemon(presentConfirmDaemon);

  return (
    <ConfirmModal
      active={message.isJust}
      message={message.getOrElse(undefined)}
      onConfirm={() => {
        dispatch(confirm());
      }}
      onCancel={() => {
        dispatch(cancel());
      }}
    />
  );
}
