/**
 * From: https://usehooks.com
 */
import { useEffect } from "react";

export function useOnClickOutside(ref, handler, active = true) {
  useEffect(() => {
    if (active) {
      const listener = event => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    }
    // eslint-disable-next-line
  }, [active]);
}
