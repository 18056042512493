import React, { useMemo } from "react";
import Maybe from "data.maybe";
import { Seq, Map } from "immutable";
import { debounce } from "utils";
import { seconds } from "utils/dates";
import { REHYDRATE } from "utils/persistentStorage";
import { selectRehydrating } from "store/app/selectors";
import appPersistor from "store/app/persistence";
import authPersistor from "store/auth/persistence";
import systemsPersistor from "store/systems/persistence";
import reportsPersistor from "store/reports/persistence";
import useEffectOnce from "hooks/useEffectOnce";

const persistors = {
  app: appPersistor,
  auth: authPersistor,
  systems: systemsPersistor,
  reports: reportsPersistor,
};

const Context = React.createContext({ persistors });

export const initPersistence = (store) => {
  let unsubscribe = () => {};

  const promise = (async () => {
    const currentState = store.getState();
    const keys = Object.keys(persistors);
    const now = Date.now();

    const persistedState = await Promise.all(
      Seq(keys)
        // The reducers for some persistors may be injected asynchronously
        .filter((key) => currentState.has(key))
        .map((key) =>
          persistors[key].rehydrate(
            now,
            Maybe.fromNullable(currentState.get(key))
          )
        )
        .toArray()
    ).then((results) =>
      Seq(results).reduce(
        (acc, state, index) => acc.set(keys[index], state),
        Map()
      )
    );

    store.dispatch({ type: REHYDRATE, payload: persistedState });

    unsubscribe = store.subscribe(
      debounce(seconds(0.2), () => {
        const state = store.getState();
        const now = Date.now();

        return Promise.all(
          Seq(persistors)
            // The reducers for some persistors may be injected asynchronously
            .filter((_, key) => state.has(key))
            .map((persistor, key) => persistor.persist(now, state.get(key)))
            .valueSeq()
            .toArray()
        );
      })
    );
  })();

  return {
    read() {
      const rehydrating = selectRehydrating(store.getState());
      if (rehydrating) {
        throw promise;
      } else {
        return unsubscribe;
      }
    },
  };
};

function StateRehydrator({ resource, children }) {
  const unsubscribe = resource.read();

  useEffectOnce(() => unsubscribe);

  return (
    <Context.Provider value={useMemo(() => ({ persistors }), [])}>
      {children}
    </Context.Provider>
  );
}

StateRehydrator.Consumer = Context.Consumer;

export default StateRehydrator;
