/**
 *
 * General app configuration settings
 * @author Matt Shaffer
 *
 *
 */
export const COOKIE_EXPIRATION_LENGTH_IN_DAYS = 30;
export const MAX_POLL_COUNT = 240;
export const POLL_DELAY_IN_MILLISECONDS = 500;
export const POLL_DOOR_STATUS_DELAY = 7000; // Range of 0-1, with 0 requiring an exact match and 1 requiring no match

export const FUSEJS_SCORE_THRESHOLD = 0.2;
export const FUSEJS_SCORE_DISTANCE = 1000;
export const MAX_DAYS_IN_ON_DEMAND_EVENT = 30;
export const DEFAULT_REALTIME_DOOR_EVENTS_TO_FETCH = 1000;
export const APP_ELEMENT = document.getElementById("root");
