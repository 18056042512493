/**
 *
 * Api Tokens
 * @author Chad Watson
 *
 */

import environment from "./environment";

// eslint-disable-next-line prefer-destructuring
export const HTTP_ACCESSIBLE_TYPE = environment.HTTP_ACCESSIBLE_TYPE;
