/**
 *
 * ErrorBoundaryContainer
 * @author Chad Watson
 *
 *
 */
import ErrorBoundary from "components/ErrorBoundary";
import React from "react";

type ErrorBoundaryContainerProps = {
  children?: React.ReactNode;
  className?: string;
};

export default function ErrorBoundaryContainer({
  children,
  className,
}: ErrorBoundaryContainerProps) {
  return <ErrorBoundary className={className}>{children}</ErrorBoundary>;
}
